import React, { useEffect, useRef, useState } from "react";
import "./WidgetLayout.scss";
import chatbotmsg from "../../../Assets/Images/clip.png";
import chatbotsend from "../../../Assets/Images/chatbotsend.png";
import loader from "../../../Assets/Images/loading.png";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import { getApi, postApi } from "../../../utils/apiService";
import { errorToast, successToast } from "../../../services/ToastHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import {
  ADD_SCRAPE_LINK,
  CHAT_BOT_API,
  CLEAR_CHAT_HISTORY,
  GET_CHAT_HISTORY_ENTERPRICE,
} from "../../../utils/apiPath";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { BackArrow } from "../../../Assets/icons";
import FileUrlModal from "../../../Components/FileUrlModal/FileUrlModal ";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import Buttons from "../../../Components/Buttons/Buttons";
import AdminConfirm from "../../../Components/AdminConfirm/AdminConfirm";

const WidgetLayout = () => {
  const initialvalues = {
    link: "",
  };
  const { id } = useParams();
  const { state } = useLocation();
  const { llmName, llmId } = state || {};
  const [isMessage, setIsMessage] = useState("");
  const [isResponse, setIsResponse] = useState("");
  const [chatData, setChatData] = useState([]);
  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const navigate = useNavigate();
  const [selectedChatIndex, setSelectedChatIndex] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileOrUrl, setFileOrUrl] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadLink, setUploadLink] = useState(initialvalues);
  const [confirmVisible, setConfirmVisible] = useState(false);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatData, chatHistory]);

  useEffect(() => {
    // Scroll to bottom on page load
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (!isResponse) {
      textareaRef.current.focus();
    }
  }, [isResponse]);

  const handleChatResponse = async () => {
    setIsResponse(true);
    const payload = {
      question: isMessage,
      llmId: id,
    };
    const { status, data, message } = await postApi(CHAT_BOT_API, payload);
    if (status === 200) {
      const formattedData = parseResponse(data?.answer);
      setChatData([
        ...chatData,
        { question: data?.question, result: formattedData },
      ]);
      // fetchChatHistory();
      setIsMessage("");
      setTimeout(() => {
        setIsResponse(false);
      }, 0);
    } else {
      errorToast(message);
    }
  };

  const handleDeleteChatHistory = async () => {
    const payload = {
      llmId: id,
    };
    const { status, data, message } = await postApi(CLEAR_CHAT_HISTORY, payload);
    if (status === 200) {
      successToast("Chat history deleted successfully!");
      fetchChatHistory();
      setConfirmVisible(false)
      setChatHistory([]);
      setChatData([]);
    } else {
      errorToast(message);
    }
  };

  const handleDeleteChatModal = (fileId) => {
    setConfirmVisible(true);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected for upload");
      return;
    }
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("fileUpload", file);
      formData.append("llmId", id);
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios.post(
        "https://enterprise-api.myaisquad.com/api/v1/enterprise/llm/uploadfile",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      setIsLoading(false);
      setFile("");
      successToast(response.message);
      setTimeout(() => {
        setIsResponse(false);
      }, 0);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleLinkChange = (name, value) => {
    setUploadLink({
      ...uploadLink,
      [name]: value,
    });

    setIsModalOpen(false);
  };

  const handleUploadLink = async () => {
    setIsLoading(true);

    const payload = {
      llmId: id,
      url: uploadLink.link,
    };
    const { status, message } = await postApi(ADD_SCRAPE_LINK, payload);
    if (status === 200) {
      successToast(message);
      setIsLoading(false);
      setUploadLink("");
      setTimeout(() => {
        setIsResponse(false);
      }, 0);
    } else {
      errorToast(message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChatHistory();
  }, []);

  const fetchChatHistory = async () => {
    const { status, data } = await getApi(GET_CHAT_HISTORY_ENTERPRICE, {
      params: { llmId: id },
    });

    if (status === 200) {
      const formattedChatHistory = data?.map((item) => ({
        question: item?.userText,
        result: parseResponse(item?.llmText),
        timestamp: item?.createdAt,
      }));

      const sortedChatHistory = formattedChatHistory.sort((a, b) => {
        const dateA = new Date(a.timestamp);
        const dateB = new Date(b.timestamp);
        return dateA - dateB; // Ascending order
      });

      setChatHistory(sortedChatHistory);
    } else {
      setChatHistory([]);
    }
  };

  const parseResponse = (response) => {
    const codeRegex = /```(\w+)?\s*([\s\S]*?)\s*```/g;
    const matches = [...response?.matchAll(codeRegex)];
    const parts = response?.split(codeRegex);

    const wordsToFilter = [
      "jsx",
      "html",
      "javascript",
      "css",
      "scss",
      "python",
      "java",
      "bash",
    ];

    const parsedContent = parts?.map((part, index) => {
      if (index % 3 === 2) {
        const language =
          matches[Math.floor(index / 3)][1]?.toLowerCase() || "text";
        const filteredContent = wordsToFilter?.reduce((content, word) => {
          const wordRegex = new RegExp(`^\\s*${word}\\s*`, "i");
          return content?.replace(wordRegex, "");
        }, part.trim());
        return {
          type: "code",
          language: language,
          content: filteredContent,
        };
      }

      const paragraphs = part?.split("\n\n").map((p) =>
        p.split("\n").map((line, index) => {
          if (typeof line === "string") {
            const boldRegex = /\*\*(.*?)\*\*/g;

            const parts = line.split(boldRegex);
            return parts.map((part, partIndex) => {
              if (partIndex % 2 === 1) {
                return (
                  <span style={{ fontWeight: "bold" }} key={partIndex}>
                    {part}
                  </span>
                );
              }
              return part;
            });
          }
          return line;
        })
      );
      return {
        type: "text",
        content: paragraphs,
      };
    });
    return parsedContent.map((item) => {
      if (item.type === "text") {
        item.content = item.content?.map((paragraph) => {
          return paragraph.map((line, index) => {
            // if (Array.isArray(line)) {
            //   line = line.join(" ");
            //   if (line.startsWith("###")) {
            //     console.log(`Found heading: ${line}`);
            //     return {
            //       type: "h3",
            //       content: line.replace(/^###\s*/, "").trim(),
            //     };
            //   }
            // }

            if (typeof line === "string") {
              if (typeof line === "string") {
                if (line.startsWith("###")) {
                  return {
                    type: "h3",
                    content: line.replace(/^###\s*/, "").trim(),
                  };
                }
              }
              if (line.includes("**") && line.includes("**")) {
                return {
                  type: "h2",
                  content: line.replace(/\*\*/g, "").trim(),
                };
              }
            }
            return { type: "p", content: line };
          });
        });
      }
      return item;
    });
  };

  const renderChatContent = (content) => {
    if (!content || content.length === 0) {
      return <p>No content available.</p>;
    }

    return content.map((part, index) => {
      if (!part || !part.content) {
        return <p key={index}>Invalid content</p>;
      }

      if (part.type === "code") {
        return (
          <SyntaxHighlighter
            language={part?.language}
            style={vscDarkPlus}
            key={index}
          >
            {part?.content}
          </SyntaxHighlighter>
        );
      }

      return (
        <div key={index}>
          {part?.content?.map((paragraph, pIndex) => (
            <div key={pIndex}>
              {paragraph.map((line, lIndex) => {
                if (line.type === "h3") {
                  return <h3 key={lIndex}>{line.content}</h3>;
                }
                if (line.type === "h2") {
                  return <h2 key={lIndex}>{line.content}</h2>;
                }
                if (line.type === "p") {
                  return <p key={lIndex}>{line.content}</p>;
                }
                return null;
              })}
            </div>
          ))}
        </div>
      );
    });
  };

  const handleSubmit = () => {
    if (file) {
      handleUpload();
    } else if (uploadLink?.link) {
      handleUploadLink();
    } else if (isMessage.trim()) {
      handleChatResponse();
    } else {
      errorToast("Please enter a message, upload a file, or provide a URL.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();

      if (file) {
        handleUpload();
      } else if (uploadLink?.link) {
        handleUploadLink();
      } else if (isMessage.trim()) {
        handleChatResponse();
      } else {
        errorToast("Please enter a message, upload a file, or provide a URL.");
      }
    }
  };

  const hanldeLLMPage = () => {
    navigate(-1);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
    setIsModalOpen(false);
  };

  const handleUrlChange = (e) => {
    const url = e.target.value.trim();
    if (url) {
      setFileOrUrl(url);
    }
    setIsModalOpen(false);
  };

  return (
    <>
      {confirmVisible && (
        <AdminConfirm
          title={"Are you sure you want to delete the chat history?"}
          onCancel={() => setConfirmVisible(false)}
          onConfirm={handleDeleteChatHistory}
        />
      )}
      <CustomLayout>
        <div className="widget">
          <div className="widget_header">
            <div className="widget_header_left">
              <span onClick={hanldeLLMPage}>
                <BackArrow />
              </span>
            </div>
            <div className="widget_header_middle">
              <h3>{llmName || "Chat Bot"}</h3>
            </div>
            <div className="widget_header_right">
              <Buttons
                className="widget_header_right_button"
                variant="primary"
                onClick={handleDeleteChatModal}
              >
                Delete History
              </Buttons>
            </div>
          </div>
          <div className="widget_main">
            <div className="widget_main_container">
              <div className="widget_main_suggestions" ref={chatContainerRef}>
                {chatHistory.map((chat, index) => (
                  <div key={chat._id} className="chat_message">
                    <div className="chatbot_main_suggestions_box1">
                      <h5>{chat.question}</h5>
                    </div>
                    <div className="chatbot_main_suggestions_box2">
                      {renderChatContent(chat?.result)}
                    </div>
                  </div>
                ))}
                {chatData?.map((res, index) => (
                  <div key={index} className="tempdata">
                    <div className="chatbot_main_suggestions_box1">
                      <h5>{res.question}</h5>
                    </div>
                    <div className="chatbot_main_suggestions_box2">
                      {renderChatContent(res?.result)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="widget_main_message">
                <div
                  className="chatbotmsgimg"
                  onClick={() => setIsModalOpen(true)}
                >
                  <img src={chatbotmsg} alt="chatbotmsg" />
                </div>
                {isModalOpen && (
                  <div className="modal">
                    <div className="modal_content">
                      <h3>Select File or Enter URL</h3>
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                      />
                      <FormInputs
                        title={"Enter WebLink to Scrape here"}
                        placeholder="Enter Weblink"
                        type={"text"}
                        name="link"
                        value={uploadLink.link}
                        onChange={handleLinkChange}
                      />
                      <div className="modal_content_buttons">
                        <Buttons
                          variant="primary"
                          onClick={() => setIsModalOpen(false)}
                        >
                          Close
                        </Buttons>
                      </div>
                    </div>
                  </div>
                )}

                <textarea
                  ref={textareaRef}
                  type="text"
                  placeholder="Send a message"
                  value={isMessage || uploadLink.link || file?.name || ""}
                  disabled={isResponse === true}
                  onChange={(e) => setIsMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <div className="chatbotsendimg">
                  {isResponse === true ? (
                    <img className="loader" src={loader} alt="Loading" />
                  ) : (
                    <img
                      className="send"
                      src={chatbotsend}
                      alt="chatbotsend"
                      onClick={handleSubmit}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomLayout>
    </>
  );
};

export default WidgetLayout;
